<template>
    <div class="MultipleBaccarat flex warp">
        <div v-for="(item,index) in ImgArray" :key="index" @click="BetClick(index)" class="relative pointer">
            <img src="../../../public/img/Multiple/Baccarat/z.png" class="absolute allw allh">
            <img src="../../../public/img/Multiple/Baccarat/z_hover.png" class="absolute allw allh">
            <img :src="'./img/Multiple/Baccarat/'+item + '_words'+ (LangeuageAgent === 'CH' ? '' : LangeuageAgent) +'.png'"
                 :style="[LangeuageAgent === 'CH' ? '' : SetWidth(item)]" class="absolute">
            <span class="absolute font14">{{Proportion[index]}}</span>
            <div class="ChipEffect absolute">
                <!--                top: -.5rem-->
                <span v-show="BetMoney[BetIndex[index]].url.length !== 0"
                      :style="{'marginTop': -4.5*BetMoney[BetIndex[index]].url.length*whdef+'px'}"
                      class="absolute font18">{{BetMoney[BetIndex[index]].money}}</span>
                <img v-for="(l,i) in BetMoney[BetIndex[index]].url" :key="i+l" :src="'./img/Common/xz'+l+'.png'"
                     :style="{'marginBottom': i*4*whdef+'px'}" class="absolute allw">
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'MultipleBaccarat',
        props: {
            'TableInfo': {
                default() {
                    return {}
                }, type: Object
            },
            'Count': [Number],
            'GameId': [Number],
            'SetBetState': [Function],
            'CloseBetState': [Function]
        },
        computed: {
            ...mapState(['whdef', 'UserInfo', 'SocketFn', 'LangeuageAgent'])
        },
        data() {
            return {
                CallBack: ['CalllConfirmBetFn', 'CalllRevokeBetFn'],
                ImgArray: ['x', 'z', 'xd', 'h', 'zd'],
                BetIndex: [1, 0, 4, 2, 3],    //下注顺序 庄 闲 和 庄对 闲对
                Proportion: ['1:1', '1:0.95', '1:11', '1:8', '1:11'],
                //下注金额
                BetMoney: [
                    {money: 0, url: [],},
                    {money: 0, url: [],},
                    {money: 0, url: [],},
                    {money: 0, url: [],},
                    {money: 0, url: [],}
                ],
                //真实下注金额
                CopyBetMoney: [],
                CopyBetMoney1: [],
            }
        },
        created() {
            this.$nextTick(() => {
                this.CopyBetMoney = this.Clone(this.BetMoney);
                this.CopyBetMoney1 = this.Clone(this.BetMoney);
                this.Init(this.TableInfo);
            });
        },
        watch: {
            'TableInfo': {
                handler(n, o) {
                    this.Init(n);
                }, deep: true
            },
        },
        methods: {
            Init(n) {
                if (n.hasOwnProperty('betInfo') && n.betInfo !== null) {  //初始化下注信息
                    this.CopyBetMoney.forEach((item, index) => {
                        item.money = n.betInfo[index];
                        item.url = this.CountUrl(item.money);
                    });
                    this.BetMoney = this.Clone(this.CopyBetMoney);
                }
            },
            BetClick(index) {  //下注效果
                if (this.TableInfo.gameStatus !== 1) return;  //不在投注状态
                let path = this.BetMoney[this.BetIndex[index]];
                path.money += Number(sessionStorage.getItem(('MultipleCurrentChipOn' + (this.Count + 1))));
                path.url = this.CountUrl(path.money);
            },
            ConfirmBet() { //确认下注   下注顺序为庄 闲 和 庄对 闲对
                // let gameId = this.$route.query.id;
                let info = this.TableInfo;
                let allBet = 0;
                let betInfo = [0, 0, 0, 0, 0];
                let msg = '';
                if (this.TableInfo.gameStatus !== 1) return; //不在投注状态
                this.BetMoney.forEach((item, index) => {
                    let disparity = item.money - this.CopyBetMoney[index].money;
                    // if (disparity < Number(info.limitRed.split('-')[0]) && disparity !== 0) msg = '投注金额小于最低限红';
                    allBet += disparity;
                    betInfo[index] = disparity;
                });
                if (allBet < Number(info.limitRed.split('-')[0])) msg = '投注金额小于最低限红';
                if (allBet > Number(this.UserInfo.balance)) {
                    this.Toast('2', this.Lang['YEBZ']);//'余额不足'
                    return;
                }
                if (allBet === 0) {
                    this.Toast('2', this.Lang['XYDYL']);//'下注金额必须大于0'
                    return;
                }
                if (msg === '投注金额小于最低限红') {
                    this.SetBetState(1);
                    this.CloseBetState();
                    return;
                }
                sessionStorage.MultipleID = this.GameId;    //缓存多台ID
                this.SocketApi.SendSock([this.GameId, this.cmd.PlayerBet, 0, {
                    gameId: this.GameId,
                    betInfo
                }], 'CalllConfirmBetFn');
            },
            CalllConfirmBetFn(res) {  //下注成功回调  需要保存真实下注金额
                if (res.status === 1 && Number(sessionStorage.MultipleID) === this.GameId) { //成功的时候
                    sessionStorage.removeItem('MultipleID');    //清除多台ID
                    this.CopyBetMoney = this.Clone(this.BetMoney);
                    this.SetBetState(0);
                    this.CloseBetState();
                    this.SetStore({Prop: 'UserInfo', Val: this.Clone({...this.UserInfo, ...{balance: res.balance}})}); //更新余额
                }
            },
            RevokeBet() {  //撤销下注
                if (!this.IsPermission()) return;
                // let gameId = this.$route.query.id;
                if (this.TableInfo.gameStatus !== 1) {
                    this.Toast('4', this.Lang['BZXZSJ']);//'不在下注时间，不能撤销下注'
                    return;
                }
                if (this.CopyBetMoney.filter(item => item.money > 0).length === 0) {
                    this.Toast('4', this.Lang['HWXZ']);//'当前未下注，不能撤销下注'
                    return;
                }
                this.SocketApi.SendSock([this.GameId, this.cmd.CancelBet, 0, {
                    gameId: this.GameId
                }], 'CalllRevokeBetFn');
            },
            CalllRevokeBetFn(res) { //撤销下注成功回调
                if (res.status === 1) { //成功的时候
                    this.CopyBetMoney.forEach((item, index) => {
                        item.money = res.betInfo[index];
                        item.url = this.CountUrl(item.money);
                    });
                    this.BetMoney = this.Clone(this.CopyBetMoney);
                    this.SetStore({Prop: 'UserInfo', Val: this.Clone({...this.UserInfo, ...{balance: res.balance}})}); //更新余额
                    this.Toast('1', this.Lang['CXCG']);//'撤销成功'
                    //     this.SetAudio('撤销下注特效');
                }
            },
            CancelBet() { //取消下注
                this.BetMoney = this.Clone(this.CopyBetMoney);
            },
            ClearTable() { //清空桌面
                this.BetMoney = this.Clone(this.CopyBetMoney1);
                this.CopyBetMoney = this.Clone(this.CopyBetMoney1);
            },
            SetWidth(item) { //动态设置外语图片宽度
                console.log(item);
                let Num = 'auto';
                if (item === 'x' || item === 'z') {
                    Num = '1.5';
                } else if (item === 'zd') {
                    Num = '1';
                }
                return {width: Num + 'rem'}
            }
        },
    }
</script>

<style lang="less">
    .MultipleBaccarat {
        height: 1.9rem;

        > div {
            width: 50%;
            height: .9rem;

            > img:nth-child(2) {
                display: none;
            }

            &:hover {
                > img:nth-child(2) {
                    display: block;
                }
            }

            > img:nth-child(3) {
                height: .38rem;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            > span {
                color: #6af076;
                left: 50%;
                bottom: .1rem;
                transform: translate(-50%, 0);
            }

            &:nth-child(2), &:nth-child(5) {
                .ChipEffect {
                    left: unset;
                    right: .25rem;
                }
            }

            .ChipEffect {
                width: .5rem;
                height: .3rem;
                bottom: .2rem;
                left: .25rem;
                z-index: 10;

                > span {
                    top: -1em;
                    color: #edcb02;
                    left: 50%;
                    transform: translate(-50%, 0);
                    transition: .5s;
                }

                > img {
                    bottom: 0;
                }
            }
        }

        > div:nth-child(3), > div:nth-child(4), > div:nth-child(5) {
            width: 33.33%;
            height: 1rem;

            > img:nth-child(3) {
                height: .24rem;
            }
        }
    }
</style>
